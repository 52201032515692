window.idp = {
  env: {
    constants: {
      BASE_URL: 'https://idp.auth-dev.autodesk.com',
      SHOW_KEEP_ME_SIGNED_IN: true,
      HCAPTCHA_SITE_KEY_SIGN_IN: 'e4740af0-2f6c-45f8-838e-3eae520a6524',
      HCAPTCHA_SITE_KEY_EMAIL_VERIFICATION: '1a88f752-54c8-4550-925a-4c0834a68b12',
      HCAPTCHA_SITE_KEY_MFA: '4f4c45c0-dbda-4ca6-b2fc-2b0ee6aa557e',
      IDSDK_LOGOUT_CLIENT_ID: 'fA8v5Ryr4e9QzKFGjyh73OKE1Gd0pJh9WfoeE6JeyLVh00NG',
      GOOGLE_ANALYTICS_ID: 'G-EHKHLJ9D0C',
      ENABLE_OPT_OUT_BANNER: false,
    },
  },
};

window.digitalData = {
  platform: {
    stop_page_load_tracking: true,
    disable_6sense: true,
  },
};
